import React, { useState, useEffect, FormEvent, ChangeEventHandler, ChangeEvent } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { useLoading } from "../shared/LoadingContext";
import { toast } from "react-toastify";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Mark } from "@mui/material/Slider/useSlider.types";

const Configure = () => {
  const DTUValues = [0, 50, 100, 200, 300, 400, 800, 1200, 1600, 2000, 2500, 3000];

  const errorValue =  0.1; 

  // Create evenly spaced marks
  const normalizedMarks = DTUValues.map((value, index) => ({
    value: (index / (DTUValues.length - 1)) * 100, // Evenly spaced between 0 to 100
    label: `${value}`, // Original label
  } as Mark));

  const normalizeValue = (value: number) => {
    const index = DTUValues.indexOf(value);
    return index >= 0 ? (index / (DTUValues.length - 1)) * 100 : 0;
  };

  // Denormalize value: Map normalized slider values back to original values
  const denormalizeValue = (value: number) => {
    const step = 100 / (DTUValues.length - 1);
    const index = Math.round(value / step);
    return DTUValues[index];
  };

  const { instance, accounts } = useMsal();

  const [autoScaleDetails, setAutoScaleDetails] = useState([]);
  const { loading, setLoading } = useLoading();
  const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
  const apiScope = "api://utilities/managefunctionapp";

  // Form State
  const [formData, setFormData] = useState({
    isScaleEnabled: true,
    downscaleTime : "",
    downscaleEDTU: 400,
    downscalePerDatabaseEDTU: [0,200],
    upscaleTime: "",
    upscaleEDTU: 1600,
    upscalePerDatabaseEDTU: [ 0, 1200],
    weekendEDTU: 400,
    weekendPerDatabaseEDTU: [ 0, 200]
  });

  const [edtuUnits, setEdtuUnits] = useState(1200);
  const [realTimePerDatabaseEDTU, setRealTimePerDatabaseEDTU] =  useState([0, 1200]);

  const fetchData = async () => {
    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });
      setLoading(true);

      const response = await axios.get(`${apiUrl}/accelerus/configure`, {
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
        },
      });

      setAutoScaleDetails(response.data as []);
      setFormData((prev)=> ({
        ...prev,
        isScaleEnabled: response.data.isScaleEnabled,
        downscaleTime: response.data.downscaleTime,
        downscaleEDTU: response.data.downscaleEDTU,
        downscalePerDatabaseEDTU: [response.data.downscalePerDatabaseEDTUMin, response.data.downscalePerDatabaseEDTUMax] ,
        upscaleTime: response.data.upscaleTime,
        upscaleEDTU:  response.data.upscaleEDTU,
        upscalePerDatabaseEDTU:  [response.data.upscalePerDatabaseEDTUMin, response.data.upscalePerDatabaseEDTUMax] ,
        weekendEDTU: response.data.weekendEDTU,
        weekendPerDatabaseEDTU: [response.data.weekendPerDatabaseEDTUMin, response.data.weekendPerDatabaseEDTUMax] 
      }))
    } catch (ex) {
      toast.error("Error Occurred!!");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSettings = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });
      const response = await axios.put(
        `${apiUrl}/accelerus/configure/settings`,
        {
          ...formData,
          upscaleTime: formatTimeTo12Hour(formData.upscaleTime),
          downscaleTime: formatTimeTo12Hour(formData.downscaleTime),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
      toast.success("Settings saved successfully!");
    } catch (error) {
      toast.error("Failed to save settings");
    } finally {
      setLoading(false);
    }
  };

  const handleRealTimeUpdate = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const accessToken = await instance.acquireTokenSilent({
        scopes: [apiScope],
        account: accounts[0],
      });
      const response = await axios.post(
        `${apiUrl}/accelerus/configure/update`,
        {
          edtuUnits: edtuUnits,
          perDatabaseUnits: realTimePerDatabaseEDTU,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.accessToken}`,
          },
        }
      );
      toast.success("Updated EDTU successfully!");
    } catch (error) {
      toast.error("Failed to update capacity of Elastic Pool.");
    } finally {
      setLoading(false);
    }
  };
  
  function getValuetext(value: number) {
    return `${denormalizeValue(value)} eDTUs`;
  }

  useEffect(() => {
    fetchData();
  }, [instance, accounts]);

  const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev)=> ({
      ...prev,
      [name]: value 
    }));
  }

  const formatTimeTo12Hour = (time: string) => {
    if (!time) return ""; // Handle empty input

    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) formattedHour -= 12; // Convert to 12-hour format
    }
    if (formattedHour === 0) formattedHour = 12; // Handle midnight case

    return `${formattedHour < 10 ? '0'+ formattedHour: formattedHour }:${minute} ${period}`;
  };



  return (
    <div className="overflow-auto min-h-full">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold text-start">Configure Compute Tier</h2>
      </div>
      <div className="border-b border-gray-200 w-full mt-2"></div>

      <form 
        onSubmit={handleSaveSettings}
        className="w-full mx-auto mt-4 bg-white rounded-xl shadow-md overflow-hidden p-6">
        <h2 className="text-md font-bold text-gray-700 mb-2">Configure Scheduled Compute Tier</h2>
        <hr className="my-4 border-gray-200" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
              <label className="font-bold">Resource Group : </label>
              <label>{(autoScaleDetails as any)?.resourceGroup}</label>
          </div>
          <div>
              <label className="font-bold">Subscription Id : </label>
              <label>{(autoScaleDetails as any)?.subscriptionId }</label>
          </div>
          <div>
              <label className="font-bold">Server : </label>
              <label>{(autoScaleDetails as any)?.sqlServerName}</label>
          </div>
          <div>
              <label className="font-bold">Elastic Pool Name : </label>
              <label>{(autoScaleDetails as any)?.elasticPoolName }</label>
          </div>
          
        </div>
        <hr className="col-span-2 my-4 border-gray-200" />
        <div className="flex items-center mt-4 mb-4">
            <label className="text-sm font-medium text-gray-700" htmlFor="isScaleEnabled">
              <input
                name="isScaleEnabled"
                type="checkbox"
                checked={formData.isScaleEnabled}
                onChange={(e: ChangeEvent<HTMLInputElement>)=>{
                  const { name } = e.target;
                  setFormData((prev)=> ({
                    ...prev,
                    [name]: e.target.checked 
                  }));
                }}
                className="form-checkbox mr-2"
              />
              Enable Schedule
            </label>
        </div>
        <h6 className="text-md font-medium text-gray-700 mb-2">Weekdays Settings</h6>
        <div className="grid grid-cols-1 md:grid-cols-[auto,1fr,1fr] gap-8 mb-4">
          {/* Downscale fields */}
          <div className="min-w-[100px]">
            <label className="block text-sm font-medium text-gray-700">
              Downscale Time
            </label>
            <input
              name="downscaleTime"
              type="time"
              value={formData.downscaleTime}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border rounded-md"
            />
          </div>
          <div className="p-4">
            <Box>
              <label className="block text-sm font-medium text-gray-700" htmlFor="downscaleEDTU">
                Downscale eDTU Units
              </label>
              <Slider
                name="downscaleEDTU"
                aria-label="Downscale eDTU Units"
                step={null}
                value={normalizeValue(formData.downscaleEDTU)}
                getAriaValueText={getValuetext}
                valueLabelFormat={getValuetext}
                valueLabelDisplay="auto"
                marks={normalizedMarks}
                onChange={(_: Event, newValue: number | number[] ) => setFormData((prev)=> ({ 
                  ...prev,
                  downscaleEDTU: denormalizeValue(newValue as number)
                }))}
              />
            </Box>

          </div>
          <div className="p-4">
            <Box>
                <label className="block text-sm font-medium text-gray-700" htmlFor="downscalePerDatabaseEDTU">
                  Downscale Per Database eDTU Units
                </label>
              <Slider
                name="downscalePerDatabaseEDTU"
                aria-label="Per Database eDTU Units"
                value={[normalizeValue(formData.downscalePerDatabaseEDTU[0]), normalizeValue(formData.downscalePerDatabaseEDTU[1])]}
                step={null}
                valueLabelFormat={(value) => denormalizeValue(value)} 
                valueLabelDisplay="auto"
                marks={normalizedMarks}
                disableSwap
                max={normalizeValue(formData.downscaleEDTU) + errorValue}
                onChange={(event: Event, value: number | number[]) => {
                  if (Array.isArray(value)) {
                    setFormData((prev) => ({
                      ...prev,
                      downscalePerDatabaseEDTU: [denormalizeValue(value[0]), denormalizeValue(value[1])]
                    }));
                  }
                }}
              />
            </Box>
          </div>

          {/* Upscale fields */}
          <div className="min-w-[100px]">
            <label className="block text-sm font-medium text-gray-700" htmlFor="upscaleTime">
              Upscale Time
            </label>
            <input
              type="time"
              name="upscaleTime"
              value={formData.upscaleTime}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border rounded-md"
            />
             
          </div>
          <div className="p-4">
            <Box>
                <label className="block text-sm font-medium text-gray-700" htmlFor="upscaleEDTU">
                Upscale eDTU Units
                </label>
              <Slider
                name="upscaleEDTU"
                value={normalizeValue(formData.upscaleEDTU)}
                getAriaValueText={getValuetext}
                valueLabelFormat={getValuetext}
                step={null}
                valueLabelDisplay="auto"
                marks={normalizedMarks}
                onChange={(_: Event, newValue: number | number[] ) => setFormData((prev)=> ({ 
                  ...prev,
                  upscaleEDTU: denormalizeValue(newValue as number)
                }))}
              />
            </Box>
          </div>
          <div className="p-4">
            <Box>
                <label className="block text-sm font-medium text-gray-700" htmlFor="upscalePerDatabaseEDTU">
                  Upscale Per Database eDTU Units
                </label>
              <Slider
                name="upscalePerDatabaseEDTU"
                aria-label="Upscale Per Database eDTU Units"
                value={[normalizeValue(formData.upscalePerDatabaseEDTU[0]), normalizeValue(formData.upscalePerDatabaseEDTU[1])]}
                step={null}
                valueLabelFormat={(value) => denormalizeValue(value)} 
                valueLabelDisplay="auto"
                marks={normalizedMarks}
                disableSwap
                max={normalizeValue(formData.upscaleEDTU) + errorValue}
                onChange={(event: Event, value: number | number[]) => {
                  if (Array.isArray(value)) {
                    setFormData((prev) => ({
                      ...prev,
                      upscalePerDatabaseEDTU: [denormalizeValue(value[0]), denormalizeValue(value[1])]
                    }));
                  }
                }}
              />
            </Box>
          </div>
          
        </div>

        <h6 className="text-md font-medium text-gray-700 mb-2">Weekend Settings</h6>
        <div className="grid grid-cols-1 md:grid-cols-[auto,1fr,1fr] gap-8">
          <div className="min-w-[100px]"></div>
          <div className="p-4">
              <Box>
                <label className="block text-sm font-medium text-gray-700" htmlFor="weekendEDTU">
                  Weekend eDTU Units
                </label>
                <Slider
                  name="weekendEDTU"
                  aria-label="Upscale eDTU Units"
                  value={normalizeValue(formData.weekendEDTU)}
                  getAriaValueText={getValuetext}
                  valueLabelFormat={getValuetext}
                  step={null}
                  valueLabelDisplay="auto"
                  marks={normalizedMarks}
                  onChange={(_: Event, newValue: number | number[] ) => setFormData((prev)=> ({ 
                    ...prev,
                    weekendEDTU: denormalizeValue(newValue as number)
                  }))}
                />
              </Box>
            </div>
            <div className="p-4">
              <Box>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="weekendPerDatabaseEDTU">
                    Weekend Per Database eDTU Units
                  </label>
                <Slider
                  name="weekendPerDatabaseEDTU"
                  aria-label="Downscale Per Database eDTU Units"
                  value={[normalizeValue(formData.weekendPerDatabaseEDTU[0]), normalizeValue(formData.weekendPerDatabaseEDTU[1])]}
                  step={null}
                  valueLabelFormat={(value) => denormalizeValue(value)} 
                  valueLabelDisplay="auto"
                  marks={normalizedMarks}
                  disableSwap
                  max={normalizeValue(formData.weekendEDTU) + errorValue}
                  onChange={(_: Event, value: number | number[]) => {
                    if (Array.isArray(value)) {
                      setFormData((prev) => ({
                        ...prev,
                        weekendPerDatabaseEDTU: [denormalizeValue(value[0]), denormalizeValue(value[1])]
                      }));
                    }
                  }}
                />
              </Box>
            </div>
        </div>

        <button 
          type="submit"
          className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Update Settings
        </button>
      </form>

      {/* eDTU Units Slider */}
      <form 
        onSubmit={handleRealTimeUpdate}
        className="w-full mx-auto mt-8 bg-white rounded-xl shadow-md overflow-hidden p-6">
        <h2 className="text-md font-bold text-gray-700 mb-2">Real Time Compute Tier</h2>
        <hr className="my-4 border-gray-200" />
        
        <Box>
          <label className="block text-sm font-medium text-gray-700">
          eDTU Capacity
          </label>
          <Slider
            aria-label="Per Database eDTU Units"
            value={normalizeValue(edtuUnits)}
            step={null}
            getAriaValueText={getValuetext}
            valueLabelFormat={getValuetext}
            valueLabelDisplay="auto"
            marks={normalizedMarks}
            onChange={(_: Event, newValue: number | number[] ) => setEdtuUnits(denormalizeValue(newValue as number))}
          />
        </Box>
        <div>
        <Box>
            <label className="block text-sm font-medium text-gray-700">
              Per Database eDTU Units
            </label>
          <Slider
            aria-label="Real Time per database dtu units"
            value={[normalizeValue(realTimePerDatabaseEDTU[0]), normalizeValue(realTimePerDatabaseEDTU[1])]}
            valueLabelFormat={(value) => denormalizeValue(value)} 
            valueLabelDisplay="auto"
            marks={normalizedMarks}
            step={null}
            disableSwap
            max={normalizeValue(edtuUnits) + errorValue}
            onChange={(event: Event, value: number | number[]) => {
              if (Array.isArray(value)) {
                setRealTimePerDatabaseEDTU([denormalizeValue(value[0]), denormalizeValue(value[1])]);
              }
            }}
          />
        </Box>
        </div>
          
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700">
          Update eDTU Units
        </button>
      </form>
    </div>
  );
};

export default Configure;